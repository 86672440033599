<template>
  <div class="making-money">
    <div class="making-money__item">
      <div class="making-money__item__text">
        <template v-if="!isTablet"
          >Seguimos ganando dinero
          <span class="making-money__item__text__regular">para nuestros usuarios</span></template
        >
        <template v-else
          ><span class="making-money__item__text__bold">Seguimos ganando dinero</span
          ><span class="making-money__item__text__regular"> para nuestros usuarios</span></template
        >
      </div>
      <div class="making-money__item__buttoncont">
        <div class="button-making-money" @click="buttonClick">
          <div class="button-making-money__text">
            <template v-if="!isMobile">Ver detalle</template>
            <template v-else>Ver</template>
          </div>
          <div class="button-making-money__icon"><img src="/images/icn_eye.png" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'MakingMoney',
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState(['displayWidth']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    isTablet() {
      return this.displayWidth < 691;
    },
    isMobile() {
      return this.displayWidth < 471;
    },
  },
  watch: {},
  methods: {
    buttonClick() {
      this.$emit('show');
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.making-money {
  width: 100%;
  height: 60px;
  display: inline-flex;
  justify-content: center;

  &__item {
    width: 811px;
    background: white;
    border-radius: 13px;
    border: solid 1px #68747d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0.65rem;
    @media screen and (max-width: 880px) {
      width: 94%;
    }

    &__text {
      width: 76%;
      font-family: Roboto-Black, sans-serif;
      font-size: 25px;
      font-stretch: normal;
      font-style: normal;
      line-height: 60px;
      letter-spacing: -0.25px;
      text-align: center;
      color: #132839;
      @media screen and (max-width: 830px) {
        font-size: 24px;
      }
      @media screen and (max-width: 810px) {
        font-size: 23px;
      }
      @media screen and (max-width: 790px) {
        font-size: 22px;
      }
      @media screen and (max-width: 760px) {
        font-size: 21px;
      }
      @media screen and (max-width: 730px) {
        font-size: 20px;
      }
      @media screen and (max-width: 710px) {
        font-size: 19px;
      }
      @media screen and (max-width: 690px) {
        line-height: 1.1;
        text-align: left;
      }
      @media screen and (max-width: 376px) {
        font-size: 17px;
      }
      @media screen and (max-width: 345px) {
        font-size: 15px;
      }
      @media screen and (max-width: 325px) {
        font-size: 14px;
      }
      &__regular {
        font-family: Roboto-Medium, sans-serif;
        font-size: 25px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: -0.3px;
        text-align: left;
        color: #132839;
        @media screen and (max-width: 830px) {
          font-size: 24px;
        }
        @media screen and (max-width: 810px) {
          font-size: 23px;
        }
        @media screen and (max-width: 790px) {
          font-size: 22px;
        }
        @media screen and (max-width: 760px) {
          font-size: 21px;
        }
        @media screen and (max-width: 730px) {
          font-size: 20px;
        }
        @media screen and (max-width: 710px) {
          font-size: 19px;
        }
        @media screen and (max-width: 690px) {
          font-size: 19px;
        }
        @media screen and (max-width: 370px) {
          font-size: 17px;
        }
        @media screen and (max-width: 345px) {
          font-size: 15px;
        }
        @media screen and (max-width: 325px) {
          font-size: 13.5px;
        }
      }
      &__bold {
        font-family: 'Heebo';
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: -0.3px;
        text-align: left;
        color: #132839;
        @media screen and (max-width: 690px) {
          font-size: 19px;
        }
        @media screen and (max-width: 370px) {
          font-size: 17px;
        }
        @media screen and (max-width: 345px) {
          font-size: 15px;
        }
        @media screen and (max-width: 325px) {
          font-size: 13.5px;
        }
      }
    }
    &__buttoncont {
      width: auto;
    }
  }
  .button-making-money {
    width: 186px;
    height: 44px;
    border-radius: 7px;
    background-color: #132839;
    display: inline-flex;
    justify-content: center;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
    @media screen and (max-width: 470px) {
      width: 94px;
      padding-top: 11px;
    }
    &__icon {
      width: 25px;
      height: 18px;
      margin-top: 9px;
      margin-left: 4px;
      @media screen and (max-width: 470px) {
        margin-top: 0px;
      }
    }
    &__text {
      font-family: 'Heebo';
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 44px;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      padding-right: 5px;
      @media screen and (max-width: 470px) {
        line-height: 24px;
      }
    }
  }
}
</style>
